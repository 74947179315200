import One from '../images/artwork/largeCollage/Bedlam.jpg'
import Two from '../images/artwork/largeCollage/IMG_5704.jpg'
import Three from '../images/artwork/largeCollage/StreetcarCeiling.jpg'


export const photos = [

      {
        src: One,
        width: 3,
        height: 2,
        title: "'Bedlam', Permanant collection at the Crossroads Hotel in Kansas City, 2018. Photo courtesy of the Crossroads Hotel."
      },
      {
        src: Two,
        width: 1,
        height: 1.25,
        title: "'Celestial Heap' installed on the ceiling of one of the Kansas City street cars, 2018"
      },
      {
        src: Three,
        width: 1,
        height: 1.25,
        title: "'Celestial Heap' installed on the ceiling of one of the Kansas City street cars, 2018"
      },
  ];